/**
 * Type: テンプレート
 * What: お得情報詳細
 */
import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import BreadcrumbComponent from '../components/Breadcrumb';
import HtmlEscape from '../util/htmlEscape';
import { Btn, Button, Text } from '../components/Btn';

const pageSlug = 'deal';
const pageTitle = 'お得情報詳細';

export const DealTemplate = ({ content, title }) => (
  <>
    <SEO title={title} />
    <BreadcrumbComponent hierarchy={2} bread2title={title} />
    <section className="section" id="postDetail">
      <div className="container">
        <h2 className="title is-3">お得情報</h2>
        <div className="border-box">
          <h3 className="title is-size-3 has-text-weight-bold is-bold-light">
            {title}
          </h3>
          <div
            dangerouslySetInnerHTML={{ __html: HtmlEscape(content) }}
            className="entry-content"
          />
        </div>
      </div>
      <div className="btn-wrap">
        <Button href="/">トップへ戻る</Button>
      </div>
    </section>
  </>
);

DealTemplate.defaultProps = {
  content: PropTypes.node.isRequired,
  title: PropTypes.string,
};

const DealPost = ({ data }) => {
  const { wordpressWpDeal: deal } = data;

  return (
    <Layout pageSlug={pageSlug}>
      <SEO title={pageTitle} />
      <DealTemplate content={deal.content} title={deal.title} />
    </Layout>
  );
};

DealPost.defaultProps = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.string,
  }),
};

export default DealPost;

export const pageQuery = graphql`
  query DealeById($id: String!) {
    wordpressWpDeal(id: { eq: $id }) {
      title
      content
    }
  }
`;
